import React, { Fragment } from 'react'
import { Modal } from 'reactstrap'
import './modalHome.scss'
import iconX1 from '../../images/home/iconX1.svg'

const videoHome = (props) => {
  return (
    <Fragment>
      <Modal
        isOpen={props.active}
        toggle={() => props.setActive(false)}
        className="ModalHome"
      >
        <div className="row" onClick={() => props.setActive(false)}>
          {/* <div className="col-11">
            <h2 id="text-modal">VÍDEO INSTITUCIONAL DA SAVASA IMPRESSORES</h2>
          </div> */}
          <div className="col-12 mb-2 pl-lg-2 pl-0">
            <div className="fecharModal">
              <a
                style={{
                  textAlign: 'right',
                  cursor: 'pointer'
                }}
                onClick={() => props.setActive(false)}
              >
                <img
                  src={iconX1}
                  alt=""
                  className="mb-lg-0 mb-2"
                  style={{
                    width: '15px',
                    height: '15px'
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div
            className="video"
            style={{
              position: 'relative',
              paddingBottom: '56.25%' /* 16:9 */,
              paddingTop: 0,
              height: 0
            }}
          >
            <iframe
              allow="autoplay"
              allowFullScreen={true}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src={'https://www.youtube.com/embed/gMY9rKchsMA?autoplay=1&controls=0&modestbranding=0'}
              frameBorder="0"
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}
export default videoHome
